import { useAuth } from "../hooks/useAuth";
import { ChangeEvent, FormEvent, useState } from "react";
import { CustomInput } from "../components/common/customInput";
import { CustomButton } from "../components/common/customButton";
import { toast } from "sonner";
import Logo from "../assets/icons/logo.svg?react";

import "../styles/pages/loginPage.css";

export const LoginPage = () => {
  const [form, setForm] = useState({ username: "", password: "" });
  const { login, authRequestLoading: loading } = useAuth();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!form.password || !form.username || loading) return;

    try {
      await login(form.username, form.password);
    } catch (e) {
      if (e?.response?.status === 401) {
        toast.error("Неправильный логин или пароль");
      } else {
        toast.error(e.message);
      }
      console.log(e);
    }
  };

  return (
    <div className="login-page">
      <div className="login-page-leftside-wrapper">
        <div className="login-page-leftside">
          <span className="login-page-light" />
          <div className="login-page-logo">
            <Logo />
            eogapp
          </div>
          <div className="login-page-leftside-footer">
            <span className="login-page-leftside-footer-name">eog app</span>
            <div>
              Платформа, которая <b>упрощает работу</b> магазинов на <b>OZON</b>
            </div>
          </div>
        </div>
      </div>
      <div className="login-page-rightside">
        <form className="login-form" onSubmit={onSubmit}>
          <div className="text-white text-4xl font-medium text-center flex items-center">
            Вход
          </div>
          <div className="login-form-fields">
            <CustomInput
              id="login-username"
              autoFocus
              name="username"
              type="text"
              label="Логин"
              placeholder="Введите логин"
              onChange={handleChange}
              required
              tabIndex={1}
            />
            <CustomInput
              id="login-password"
              name="password"
              type="password"
              label="Пароль"
              placeholder="Введите пароль"
              onChange={handleChange}
              autoComplete="on"
              required
              tabIndex={2}
            />
          </div>
          <CustomButton disabled={loading} isLoading={loading} tabIndex={3}>
            Войти
          </CustomButton>
        </form>
      </div>
    </div>
  );
};
